<template>
  <v-container fluid>
    <searchPanel></searchPanel>
    <br/>
    <v-card v-if="getStudentData.length > 0">
        <v-toolbar dark color="primary">
          <v-toolbar-title>
            Registration Card Request For
            <span>
              Correction
            </span>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
            <v-card-text>
              <v-container grid-list-md fluid style="padding-top:0px">
                <v-layout row wrap>
                  <template>
                    <v-flex md12>
                      <div style="justify-content:right;display:flex">
                        <v-btn
                        color="primary"
                        class="ma-2 white--text"
                        @click="download"
                      >
                        Download History
                        <v-icon
                          right
                          dark
                        >
                        cloud_download
                        </v-icon>
                      </v-btn>
                      </div>
                    </v-flex>
                    <v-flex md6>
                      <v-toolbar
                        color="cyan darken-4"
                        class="white--text"
                        dense
                      >
                        <v-toolbar-title class="text-uppercase"
                          >Registered Information
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                      </v-toolbar>
                      <br>
                      <table>
                        <tr>
                          <th style="width: 23%;text-align: left;">Name</th>
                          <td>: {{ getStudentData[0].name }}</td>
                        </tr>
                        <tr>
                          <th style="text-align: left;">Father Name</th>
                          <td>: {{ getStudentData[0].father_name }}</td>
                        </tr>
                        <tr>
                          <th style="text-align: left;">Mother Name</th>
                          <td>: {{ getStudentData[0].mother_name  }}</td>
                        </tr>

                      <tr>
                        <th style="width: 23%;text-align: left;">Reg. No.</th>
                        <td>:{{ getStudentData[0].reg_no  }}</td>
                      </tr>
                      <tr>
                        <th style="width: 23%;text-align: left;">Course</th>
                        <td>:
                          {{
                            getStudentData[0].degree_group
                              ? getStudentData[0].degree_group.degree_group_name
                              : "N/A"
                          }}
                        </td>
                      </tr>
                      <tr>
                        <th style="width: 23%;text-align:left">College Name</th>
                        <td>:
                          ({{ getStudentData[0].college_code }})
                          {{ getStudentData[0].college.college_name }}
                        </td>
                      </tr>

                      </table>
                    </v-flex>

                    <v-flex md6>
                      <v-toolbar
                        color="cyan darken-4"
                        class="white--text"
                        dense
                      >
                        <v-toolbar-title class="text-uppercase"
                          >Request for Correction
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                      </v-toolbar>
                      <br>
                      <v-form>
                        <v-container>
                          <v-row>
                            <v-col
                              cols="12"
                            >
                              <v-text-field                         
                                dense
                                label="New Name"
                                class="pt-0"
                                v-model="student.name"
                                :value="getStudentData[0].name"
                                v-validate="'required'"
                                data-vv-name="new_name"                           
                                :error-messages="errors.collect('new_name')"                              
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                               <v-text-field
                                class="pt-0"
                                dense
                                v-model="student.father_name"
                                label="New Father Name"
                                :value="getStudentData[0].father_name"
                                v-validate="'required'"
                                data-vv-name="new_father_name"                           
                                :error-messages="errors.collect('new_father_name')">
                            </v-text-field>
                            </v-col>
                            <v-col
                              cols="12"
                            >
                              <v-text-field
                              class="pt-0"
                                dense
                                v-model="student.mother_name"
                                label="New Mother Name"
                                :value="getStudentData[0].mother_name"
                                v-validate="'required'"
                                data-vv-name="new_mother_name"                           
                                :error-messages="errors.collect('new_mother_name')"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                              <v-btn
                                  color="primary"
                                  dark
                                  @click="submit"
                                  class="px-5 ms-0"
                                >
                              Update
                            </v-btn>
                            </v-col>
                          </v-row>
                          </v-container>
                          
                      </v-form>
                    </v-flex>
                  </template>
                </v-layout>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <div class="justify-center layout px-0 mt-2">
                
              </div>
            </v-card-actions>
            <v-spacer class="mb-3"></v-spacer>
        </v-card-text>
      </v-card>
  </v-container>
</template>
<script>
import searchPanel from "@/components/registration/correctionAdmin/regCorrectionSearch.vue";
import moment from "moment";

export default {
  $_veevalidate:{
    validator: 'new'
  },
  components: {
    searchPanel
  },

  data: () => ({
    selected: [],
    pagination: {
      rowsPerPage: 10
    },
    loading: true,
    searchParam: {},
    search: "",
    student:{
      std_id:'',
      reg_no:'',
      name: '',
      father_name: '',
      mother_name: '',
      prev_name: '',
      prev_father_name: '',
      prev_mother_name: ''
    }
  }),

  computed: {
    getStudentData() {
      let data = this.$store.getters.getStudentData;
      if(data.length > 0){
        this.student.std_id = data[0].std_id;
        this.student.reg_no = data[0].reg_no;
        this.student.name = this.student.prev_name = data[0].name;
        this.student.father_name = this.student.prev_father_name = data[0].father_name;
        this.student.mother_name = this.student.prev_mother_name = data[0].mother_name;
      }    
      return data;
    }
  },

  watch: {
    pagination() {
      this.getDataFromApi();
    }
  },

  created() {
    //this.$store.dispatch("fetchRegCorrectionList");
  },

  methods: {

    submit(){
      this.$validator.validateAll().then(isValid => {
        if(isValid){
          this.update();
        }
      })
    },
    update() {
      this.loading = true;
      this.$store
        .dispatch("updateStudents", this.student)
        .then(data => {
          this.loading = false;
        });
    },
    download() {
      console.log(this.student.reg_no);
      let paramsData = {
        reg_no: this.student.reg_no
      };
      // this.$store.dispatch("exportCorrectionRgCard", paramsData);
      this.$store.dispatch("exportCorrectionRgCardHistory", paramsData);
    }
  }
};
</script>

<style scoped>
table>tr>td{
  padding-top: 5px;
  font-size: 15px;
}
v-text-field{
  font-size: 15px;
}
</style>
